<template>
  <section class="mfo__banner">
    <img
        class="mfo__banner-img"
        src="@/assets/magento/magento-custom-development/magento-custom-development@2x.jpg"
        alt="Custom development for magento"
        loading="lazy"
        modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
    />
    <h1 class="mfo__banner-title">
      Custom development for magento
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>Top-notch effective custom solutions for your individual business goals & needs.</p>
    <p>Are you looking for unique ideas for your fast-growing eCommerce platform that would help your business stand out from competitors? WDevs team helps our clients analyze & define their needs, discover the benefits of custom-tailored development for your webstore and develop, as well as implement the best possible solutions.</p>
    <p>We work with the top eCommerce companies and online Magento-based businesses, providing maximum functionality, fast & secure performance, and complete support.</p>
    <p>Contact us to discuss your project & get your personal quote.</p>
  </section-layout>

  <h2 style="text-align:center">What you get</h2>

  <section-layout name="mfo__design" title="Custom design" margin="true">
    <p>Personalize your customers’ experience & deliver your brand’s values and mission in an easy format. Whether it’s a custom Magento theme, 3rd party extension integration, or built-from-scratch exclusive feature for your website, we will help you create a unique design, display product items the way you want them to be presented to the customers, and customize all elements of the store to meet your marketing & sales goals.</p>
  </section-layout>

  <section-layout name="mfo__functionality" title="Functionality & performance optimization" margin="true">
    <p>Magento provides a wide range of standard features that might work well with your business, but if you strive to get more from what you do, you need to comprehensively upgrade your eCommerce platform — and that’s where our team can help you.</p>
    <p>We have more than 15 years of Magento experience, developing and implementing various custom functionalities to improve your customers experience, automate business processes and drive your brand to the top of the search results in your industry.</p>
  </section-layout>

  <section-layout name="mfo__responsive-design" title="Responsive design solutions" margin="true">
    <p>Want to run your store efficiently for each customer, regardless of their OS, screen size, or type of device? WDevs are ready to customize your existing design to make it more responsive or develop a completely new solution for you.</p>
    <p>Using responsive design, you can improve your SEO rankings, deliver a steady & comfortable experience for your site visitors, reduce your costs by having one website version for all  devices, and secure functionality, as well as a unique identity for your brand.</p>
  </section-layout>

  <section-layout name="mfo__system-integrations" title="Custom 3rd party system integrations" margin="true">
    <p>If you seek to expand your business opportunities by connecting your Magento platform with other tools; such as  payment gateways, CRM or any other 3rd party solution - custom integration is your ultimate choice. Our team of experienced developers will help you to select the most favorable integration option and build the business environment you need.</p>
    <p>Custom integration by WDevs allows for an increase in efficiency & quality management of your eCommerce brand, as well as optimized sales processes. We offer a broad variety of solutions – from simple data import/export exchange, to complex synchronization with custom ERP systems.</p>
  </section-layout>

  <section-layout name="mfo__modules" title="Custom Modules & Extensions Development for Magento" margin="true">
    <p>Custom extensions are an effective way to add new features & functionality to your Magento-based website. Of course, there are plenty of out-the-box solutions on the market these days, but if you want to enhance your users’ shopping experience & find  the best option that fits the bill, our WDevs team can create customized Magento modules or extensions according to your specific requirements.</p>
    <p>We will develop your webstore ideas, and how you’d like to implement them, together with you; then our team will create a plan for your custom extension development, write code & carry out tests to meet all the quality & performance standards, as well as deploy the extension on your website & support its maintenance on a regular basis.</p>
    <p>We are in official partnerships with major Magento vendors and have a proven track record in building front-end & back-end extensions for Magento 1 and Magento 2, which will significantly  align to all your needs. With our custom solutions you can significantly speed up your website performance, increase sales, integrate various payment options & ensure a secure shopping experience for your customers.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img,
    picture {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 72px;
      max-width: 1150px;
    }
  }

  &__first,
  &__design,
  &__functionality,
  &__responsive-design,
  &__system-integrations,
  &__modules {
    .section_content {
      display: block;
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
